<template>
  <div class="editor w-100">
    {{ canEdit }}
    <editor v-model="editorValue" :init="init" :disabled="canNotEdit"></editor>
  </div>
</template>
<script>
import tinymce from "tinymce/tinymce.js";
import "tinymce/skins/ui/oxide/skin.css";
import "tinymce/themes/silver";
import "tinymce/icons/default";
import "tinymce/plugins/emoticons";
import "tinymce/plugins/emoticons/js/emojis.js";
import "tinymce/plugins/table";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/image";
import "tinymce-i18n/langs5/zh_TW.js";
import Editor from "@tinymce/tinymce-vue";
import axios from "axios";

export default {
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    plugins: {
      type: [String, Array],
      default: "quickbars emoticons table image",
    },
    toolbar: {
      type: [String, Array],
      default: "save image| bold italic underline strikethrough | fontsizeselect | forecolor backcolor | alignleft aligncenter alignright alignjustify|bullist numlist |outdent indent blockquote | undo redo | axupimgs | removeformat | emoticons |table",
    },
    canNotEdit: {
      type: Boolean,
    },
  },
  data() {
    return {
      init: {
        language: "zh_TW",
        height: 300,
        menubar: false,
        content_css: false,
        skin: false,
        plugins: this.plugins,
        toolbar: this.toolbar,
        quickbars_insert_toolbar: false,
        branding: false,
        paste_data_images: true,
        images_upload_handler: function (blobInfo, success) {
          var formData;
          var file = blobInfo.blob();
          formData = new FormData();
          formData.append("file", file, file.name);
          axios({
            method: "post",
            url: `${process.env.VUE_APP_URL_API}/images/image`,
            data: formData,
          })
            .then(res => {
              success(res.data);
            })
            .catch(err => {
              console.log(err);
            });
        },
      },
      editorValue: this.value,
    };
  },
  mounted() {
    tinymce.init({});
  },
  watch: {
    value(newValue) {
      this.editorValue = newValue;
    },
    editorValue(newValue) {
      this.$emit("input", newValue);
    },
  },
  methods: {},
};
</script>
<style>
.editor {
  border: 1px solid #e0e0e0;
}
.tox-tinymce {
  border: none !important;
}
</style>
